import React from 'react';
import { NumberInput, BooleanField,RefreshButton, ExportButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput, BooleanInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { RadioButtonGroupInput, Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import CreateEmployeeDraftEmailButton from './CreateEmployeeDraftEmailButton';
import './index.css';
import {EmployeeGenReleaseURL} from './empgenrelUrl.js';

const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validatePIN = [required(), regex(/[0-9][0-9][0-9][0-9]/, "Must be 4 digit number")];
const validatePrg = [required()];
// const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDate = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateClass = [required()];
const validateSex = [required()];
const validateEStatus = [required()];

// console.log("Env:" + process.env.NODE_ENV)


const EmployeeFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Employment Status" validate={validateEStatus} source="EStatus" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Active', name: 'Active' },
            { id: 'InActive', name: 'InActive' },
            { id: 'Terminated', name: 'Terminated' },
        ]} />
        {/* <SelectInput alwaysOn label="Classroom" source="Classroom" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Classroom 01', name: 'Classroom 01' },
            { id: 'Classroom 02', name: 'Classroom 02' },
            { id: 'Classroom 03', name: 'Classroom 03' },
            { id: 'Classroom 04', name: 'Classroom 04' },
        ]} /> */}
    </Filter>
);

const OfficeMgrFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Employment Status" validate={validateEStatus} source="EStatus" allowEmpty={false} choices={[
            { id: 'Active', name: 'Active' },
        ]} />
    </Filter>
);

const ActionsRefresh = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate = false, // you can hide CreateButton if hasCreate = false
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => (
    <CardActions>
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
    </CardActions>
);

const DetailsPanel = ({ id, record, resource }) => {
    const txt = "<div>Sex: " + record.Sex + "</div>" +
        "<div>Date of Birth: " + record.Dob + "</div>" +
        "<div>Date of Joining: " + record.Doj + "</div>"
    return (
        <div dangerouslySetInnerHTML={{ __html: txt }} />
    );
}

const Tenure = ({ record }) => {
    var joining=record.Doj.split("/");
    var dojMonth= joining[0]; 
var dojDay= joining[1];
var dojYear= joining[2];
var now = new Date();
var nowDay= now.getDate();
var nowMonth = now.getMonth() + 1;  //jan = 0 so month + 1
var nowYear= now.getFullYear();

var tenureyear = nowYear - dojYear;
var tenuremonth = nowMonth - dojMonth;
var tenureday = nowDay- dojDay;
// console.log(tenureyear,tenuremonth,tenureday)
if (nowDay < dojDay) {
    tenuremonth--;
    tenureday = 30 + tenureday;
    }
if (tenuremonth < 0) {
       tenureyear--;
       tenuremonth = (12 + tenuremonth);
        }

var val = tenureyear + "yrs, " + tenuremonth + "mnth(s), "+tenureday+"day(s)";
return <span>{val}</span>
};

export const EmployeesList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions !== undefined && permissions.role === 'admin') {
        return (
            <List  {...props} filters={<EmployeeFilter />} filterDefaultValues={{ EStatus: "Active", Classroom: "All" }} bulkActionButtons={<EmployeeListActions {...props} />} sort={{ field: 'Name', order: 'ASC' }} >
                {isSmall ? (
                    <Datagrid>
                        <TextField label="Employee Name" source="Name" />
                        <TextField label="Class" source="Classroom" />
                        <TextField label="Employment Status" source="EStatus" />
                        <TextField label="PIN" source="PIN" sortable={false} />
                        <TextField label="Email" source="Email" sortable={false} />
                        <TextField label="Phone" source="Tel" sortable={false} />
                        <TextField label="ImmunizationStatus" source="ImmunizationStatus" />
                        <TextField label="TB Test" source="TBTest" />
                        <TextField label="CPR Expiry Date" source="CPRExpiryDate" />
                        <EditButton />
                    </Datagrid>
                ) : (
                    <Datagrid className="txtCopy" expand={<DetailsPanel />}>
                        <TextField label="Employee Name" source="Name" />
                        <Tenure label="Tenure" />
                        <TextField label="Class" source="Classroom" />
                        <TextField label="Employment Status" source="EStatus" />
                        <BooleanField label="Salaried" source="Salaried" />
                        <TextField label="Pay" source="Pay" />
                        <TextField label="Position" source="Position" />
                        <TextField label="PIN" source="PIN" sortable={false} />
                        <TextField label="Email" source="Email" sortable={false} />
                        <TextField label="Phone" source="Tel" sortable={false} />
                        <TextField label="ImmunizationStatus" source="ImmunizationStatus" />
                        <TextField label="TB Test" source="TBTest" />
                        <TextField label="CPR Expiry Date" source="CPRExpiryDate" />
                        <EmployeeGenReleaseURL />
                        <EditButton />

                    </Datagrid>)}
            </List>
        );
    } else if (permissions !== undefined && (permissions.role === 'officemgr')) {
        return (
            <List  {...props} filters={<OfficeMgrFilter />} filterDefaultValues={{ EStatus: "Active", Classroom: "All" }} bulkActionButtons={<OfficeMgrListActions {...props} />} sort={{ field: 'Name', order: 'ASC' }} actions={<ActionsRefresh {...props} />} >
                <Datagrid expand={<DetailsPanel />}>
                    <TextField label="Employee Name" source="Name" />
                    <TextField label="Email" source="Email" sortable={false} />
                    <TextField label="Phone" source="Tel" sortable={false} />
                </Datagrid>
            </List>
        );
    } else {
        return (<span>User has No Access...</span>)
    }
};

const EmployeeTitle = ({ record }) => {
    return <span>Employee {record ? `"${record.id}"` : ''}</span>;
};

export const EmployeeEdit = (props) => (
    <Edit title={<EmployeeTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Employee Name" source="Name" validate={validateName} />
            <SelectInput source="Sex" validate={validateSex} choices={[
                { id: 'M', name: 'M' },
                { id: 'F', name: 'F' },
            ]} />
            <TextInput label="Address" source="Address" />
            <TextInput label="Phone" source="Tel" />
            <TextInput label="Email" source="Email" validate={validateEmail} />
            <BooleanInput label="Salaried" source="Salaried" />
            <NumberInput label="Pay" source="Pay" />
            <TextInput label="Date of Birth" source="Dob" validate={validateDate} />
            <TextInput label="Date of Joining" source="Doj" validate={validateDate} />
            <SelectInput label="Employment Status" source="EStatus" validate={validateEStatus} choices={[
                { id: 'Active', name: 'Active' },
                { id: 'InActive', name: 'InActive' },
                { id: 'Terminated', name: 'Terminated' },
            ]} />
            <SelectInput label="Position" source="Position" validate={required()} choices={[
                { id: 'teacher', name: 'Primary Teacher' },
                { id: 'toddlerteacher', name: 'Toddler Teacher' },
                { id: 'officemgr', name: 'Office Manager' },
                { id: 'primaryassistant', name: 'Primary Assistant' },
                { id: 'toddlerassistant', name: 'Toddler Assistant' },
            ]} />
            <SelectInput source="Classroom" validate={validateClass} choices={[
                { id: 'Classroom 01', name: 'Classroom 01' },
                { id: 'Classroom 02', name: 'Classroom 02' },
                { id: 'Classroom 03', name: 'Classroom 03' },
                { id: 'Classroom 04', name: 'Classroom 04' },
            ]} />
            <TextInput label="Termination Date" source="TerminationDate" validate={validateDateOpt} />
            <TextInput label="PIN" source="PIN" validate={validatePIN} />
            <SelectInput source="ImmunizationStatus" choices={[
                { id: 'Complete', name: 'Complete' },
                { id: 'Not Provided', name: 'Not Provided' },
                { id: 'Exemption', name: 'Exemption' },
            ]} />
            <TextInput label="CPR Expiry Date" source="CPRExpiryDate" validate={validateDateOpt} />
            <SelectInput label="TB Test" source="TBTest" choices={[
                { id: 'Negative', name: 'Negative' },
                { id: 'Positive', name: 'Positive' },
                { id: 'Not Provided', name: 'Not Provided' },
            ]} />
        </SimpleForm>
    </Edit>
);

export const EmployeeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box p="1em">
                <Box display="flex">
                    <Box >
                        <Typography variant="h6" gutterBottom>Employee Info</Typography>
                        <Box display="flex">
                            <Box mr="2em">
                                <TextInput label="Employee Name" source="Name" validate={validateName} />
                            </Box>
                            <Box  >
                                <SelectInput source="Sex" validate={validateSex} choices={[
                                    { id: 'M', name: 'M' },
                                    { id: 'F', name: 'F' },
                                ]} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box mr="2em">
                                <TextInput label="Address" source="Address" />
                            </Box>
                            <Box mr="2em">
                                <TextInput label="Phone" source="Tel" />
                            </Box>
                        </Box>
                        <Box mr="2em">
                            <TextInput label="Email" source="Email" validate={validateEmail} />
                        </Box>
                        <Box>
                            <Box mr="2em" >
                                <BooleanInput label="Salaried" source="Salaried" />
                            </Box>
                            <Box>
                                <NumberInput label="Pay" source="Pay" />
                            </Box>
                            <Box>
                                <SelectInput label="Position" source="Position" validate={required()} choices={[
                                    { id: 'teacher', name: 'Primary Teacher' },
                                    { id: 'toddlerteacher', name: 'Toddler Teacher' },
                                    { id: 'officemgr', name: 'Office Manager' },
                                    { id: 'primaryassistant', name: 'Primary Assistant' },
                                    { id: 'toddlerassistant', name: 'Toddler Assistant' },
                                ]} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box mr="2em" >
                                <TextInput label="Date of Birth" source="Dob" validate={validateDate} />
                            </Box>
                            <Box >
                                <TextInput label="Date of Joining" source="Doj" validate={validateDate} />
                            </Box>

                        </Box>
                        <Box display="flex">
                            <Box mr="6em" >
                                <SelectInput source="Classroom" validate={validateClass} choices={[
                                    { id: 'Classroom 01', name: 'Classroom 01' },
                                    { id: 'Classroom 02', name: 'Classroom 02' },
                                    { id: 'Classroom 03', name: 'Classroom 03' },
                                    { id: 'Classroom 04', name: 'Classroom 04' },
                                ]} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            {/* <Box mr="2em" >
                                <TextInput label="PIN" source="PIN" validate={validatePIN} />
                            </Box> */}
                            <Box mr="6em" >
                                <SelectInput label="Immunization Status" source="ImmunizationStatus" choices={[
                                    { id: 'Complete', name: 'Complete' },
                                    { id: 'Not Provided', name: 'Not Provided' },
                                    { id: 'Exemption', name: 'Exemption' },
                                ]} />
                            </Box>
                            <Box>
                                <SelectInput label="TB Test" source="TBTest" choices={[
                                    { id: 'Negative', name: 'Negative' },
                                    { id: 'Positive', name: 'Positive' },
                                    { id: 'Not Provided', name: 'Not Provided' },
                                ]} />
                            </Box>
                        </Box>
                        <Box>
                            <TextInput label="CPR Expiry Date" source="CPRExpiryDate" validate={validateDate} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </SimpleForm>
    </Create >
);



const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const EmployeeListActions = props => (
    <TopToolbar>
        <CreateEmployeeDraftEmailButton {...props} />
        <BulkDeleteButton {...props} undoable="true" />
    </TopToolbar>
);

const OfficeMgrListActions = (props) => {
    // console.log("In student buttons ", props);
    // let st = props.filterValues.EStatus;
    // let cl = props.cl;
    // console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
        </TopToolbar>
    );
}


