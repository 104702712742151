
import {  required } from 'react-admin';

const 	HOLIDAYS = "09/04/2023,10/09/2023,10/10/2023,10/11/2023,10/12/2023,10/13/2023,11/10/2023,11/22/2023,11/23/2023,11/24/2023,12/18/2023,12/19/2023,12/20/2023,12/21/2023,12/22/2023,12/25/2023,12/26/2023,12/27/2023,12/28/2023,12/29/2023,01/01/2024,01/15/2024,02/19/2024,03/11/2024,03/12/2024,03/13/2024,03/14/2024,03/15/2024,03/29/2024,04/19/2024,05/27/2024,06/19/2024";

const startYear = 2020;
export const validateEnrollmentDate = [required(),checkEDt]


export function getYears() {
    var d = new Date(),
        year = d.getFullYear(),
        years = [];
        if (d.getMonth() == 11) {
            year =  year + 1
        }
        while (year >= startYear) {
            years.push({ id: year, name: year });
            year = year - 1;
        }
    // console.log("Years ", years);
    return years;
}

export function getCurrYr() {
    var d = new Date(),
    year = d.getFullYear();
    return year;
}

export function isAdmin(permissions) {
    if (permissions !== undefined && permissions.role === 'officemgr') {
        return true;
    }
    return false;
}

function convertDateFormat(inputDate) {
    // Split the input date string using "/"
    const dateParts = inputDate.split('-');

    // Rearrange the parts to match "yyyy-mm-dd" format
    const dd = dateParts[2];
    const yyyy = dateParts[0]; 
    const mm = dateParts[1]; 

    // Join the parts using "-"
    const outputDate = `${mm}/${dd}/${yyyy}`;

    return outputDate;
}

export function checkEDt(value) {
    //date has to be in future and can not be holiday or weekend
    const today = new Date();    
    var dt = new Date(value);

    // Check if the date is in the future
    if (dt <= today) {
        return "Enrollment Date has to be in future";
    }
    const dayOfWeek = dt.getDay();
    // console.log("DOW",dayOfWeek);
    if (dayOfWeek === 6 || dayOfWeek === 5) {
        return "Enrollment cannot start on weekend";
    }
    // console.log("DT",convertDateFormat(value));
    if (HOLIDAYS.includes(convertDateFormat(value))) {
        return "Enrollment Date cannot be a Holiday";
    }

}